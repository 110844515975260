import { NgModule, Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule, Routes } from '@angular/router'

const routes: Routes = [
	//{ path: '', redirectTo: '/dang-nhap', pathMatch: 'full' },
	{ path: '', redirectTo: '/dang-ky/0', pathMatch: 'full' },
	//{ path: 'login', loadChildren: './modules/logins/logins.module#LoginsModule' },
	//{ path: 'quan-tri', loadChildren: './modules/business.module#BusinessModule' },
]

@NgModule({
	imports: [CommonModule, RouterModule.forRoot(routes)],
	declarations: [],
	exports: [RouterModule],
})
export class AppRoutingModule {}
