import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthenticationService } from '../../../../services/authentication.service'
import { ForgetPasswordUserObject } from '../../../../models/forgetPasswordUserObject'
import { ToastrService } from 'ngx-toastr'
import { UserCheckExist, UserObject2 } from 'src/app/models/UserObject'
import { ConfirmedPassValidator } from 'src/app/directives/pass.validator';
import { UserService } from 'src/app/services/user.service'
import { environment } from 'src/environments/environment'

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

	env = environment
	submitted: boolean = false
	user: UserObject2 = new UserObject2()
	forgetPasswordForm: FormGroup
	constructor(
		private authenService: AuthenticationService,
		private _avRoute: ActivatedRoute,
		private _router: Router,
		private toastr: ToastrService,
		private formBuilder: FormBuilder,
		private activeRoute: ActivatedRoute,
		private userService: UserService,
	) { }
	listGender: any = [
		{ value: true, text: 'Nam' },
		{ value: false, text: 'Nữ' },
	]
	_ngaySinh: Date = null;
	_ngayCap: Date = null;
	password1: string = ""
	checkPass: boolean = false
	thanhcong: number =0

	_code: string = ""
	_nhaTaiTro: string = ""
	_phone: string = ""
	_userName: string = ""
	_password: string = ""
	_password1: string = ""
	_email: string = ""
_linkios: string = ""
_linkandroid: string = ""
	step: number = 1
	inputs: ElementRef[];
	userExit: UserCheckExist = new UserCheckExist()
	@ViewChild('input0', { static: false }) input0: ElementRef;
	@ViewChild('input1', { static: false }) input1: ElementRef;
	@ViewChild('input2', { static: false }) input2: ElementRef;
	@ViewChild('input3', { static: false }) input3: ElementRef;
	@ViewChild('input4', { static: false }) input4: ElementRef;
	@ViewChild('input5', { static: false }) input5: ElementRef;
	onInputChange(index: number , event : Event) {
		
		const input = this.inputs[index].nativeElement;
		var val = (event as KeyboardEvent).key;
		// if (!isNaN(parseInt(val))) {
		// 	input.value = val; //
		// } 
		//input.value = (event as KeyboardEvent).key;
		if (input.value && index < this.inputs.length - 1) {
			this.inputs[index + 1].nativeElement.focus();
		}
	}
	ngAfterViewInit() {
		this.inputs = [
			this.input0,
			this.input1,
			this.input2,
			this.input3,
			this.input4,
			this.input5,
		];
	}
	ngOnInit() {
		let req = { UserCode: "" }
		this.userService.getLinkApp(req).subscribe(
			(resExist) => {
				debugger
				this._linkios = resExist.result.LinkIos;
				this._linkandroid = resExist.result.LinkAndroid;
			},
			(error) => {

				console.error(error)
			}
		)


		this.forgetPasswordForm = this.formBuilder.group({
			userName: ['', [Validators.required]],
			email: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+?\.[a-zA-Z]{2,4}$')]],
			fullName: ['', [Validators.required]],
			phone: ['', [Validators.required, Validators.pattern('^(84|0[2|3|4|5|7|8|9])+([0-9]{8,9})$')]],
			//password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/)]],  //'^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})'
			password: ['', [Validators.required]],
			password1: ['', [Validators.required]],
			// //unitId: ['', [Validators.required]],
			// gender: ['', [Validators.required]],
			// //roleId: ['', [Validators.required]],
			// //isActived: ['', [Validators.required]],
			// address: ['', [Validators.required ]],
			// //rank: [''],
			// ngaySinh: [null, [Validators.required]],
			// ngayCap: [null, [Validators.required]],
			// noiCap: ['', [Validators.required]],
			// soCccd: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(12)]],
			code: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(14)]],
			//isLeader :['', [Validators.required]],
		}, { validator: ConfirmedPassValidator("password", "password1") })

		this.activeRoute.params.subscribe((params) => {
			if (params['code']) {
				if (params['code'] != '' && params['code'] != '0'){
					
					this._code = params['code']

					let request = { UserCode: this._code }
					this.userService.checkExistsCodeReturn(request).subscribe(
						(resExist) => {
							if (resExist.success === "OK") {
								if (resExist.result.SYUserCheckExists.exists == true) {
									this.user.code = resExist.result.SYUserCheckExists.value
									this._code = resExist.result.SYUserCheckExists.phone
									this._nhaTaiTro = resExist.result.SYUserCheckExists.name
								}
							}
						},
						(error) => {
	
							console.error(error)
						}
					)
				}
			
			}
		})
	}

	_type: string = "password"
	HienThi: number = 0
	_type1: string = "password"
	HienThi1: number = 0
	isContentLoading: boolean = false
	isNotRobot: boolean = false
	isAccept: boolean = false

	hienThiMatKhau() {
		if (this.HienThi == 1) {
			this.HienThi = 0
			this._type = "password"
		}
		else {
			this.HienThi = 1
			this._type = "text"
		}
	}
	hienThiMatKhau1() {
		if (this.HienThi1 == 1) {
			this.HienThi1 = 0
			this._type1 = "password"
		}
		else {
			this.HienThi1 = 1
			this._type1 = "text"
		}
	}
	nextStep() {
		this.isContentLoading = true
		this.submitted = true
		if (this.forgetPasswordForm.invalid) {
			this.isContentLoading = false
			return
		}
		if (this.isNotRobot == false) {
			this.isContentLoading = false
			return
		}

		let request = { UserCode: this._code }
		this.userService.checkExistsCodeReturn(request).subscribe(
			(resExist) => {
				if (resExist.success === "OK") {
					if (resExist.result.SYUserCheckExists.exists == false) {
						this.toastr.error("Số điện thoại nhà tài trợ không đúng!")
						this.isContentLoading = false
					}
					else {
						this.user.code = resExist.result.SYUserCheckExists.value

						this._nhaTaiTro = resExist.result.SYUserCheckExists.name

						// kiểm tra tồn tại
						this.userExit.userName = this.user.userName
						this.userExit.phone = this.user.phone
						this.userExit.email = this.user.email
						this.authenService.registerCheckSSO(this.userExit).subscribe(
							(data) => {

								this.isContentLoading = false

								if (data.success === "OK") {

									this.step = 2;

								} else {
									this.toastr.error(data.message)
								}
							},
							(error) => {
								this.isContentLoading = false
								console.error(error)
							}
						)



					}

				} else {
					this.isContentLoading = false
					this.toastr.error(resExist.message)
				}

			},
			(error) => {
				this.isContentLoading = false
				console.error(error)
			}
		)
	}
	preStep(step) {
		this.step = step;
	}
	onCheckExist() {

	}
	_ThoiGian: number = 90;
	countdownInterval: any
	stopCountdown() {
		clearInterval(this.countdownInterval);
	}
	MaOTP: string = ""
	_so1: string = ""
	_so2: string = ""
	_so3: string = ""
	_so4: string = ""
	_so5: string = ""
	_so6: string = ""
	xacnhan(): void {
		this.step = 3;
		  let req = {
			SoDienThoai : this.user.phone
		  }
		this.authenService.getOTP(req).subscribe(
			(res) => {
				if (res.success === "OK") {
					this.toastr.success(res.message)
					//this._ThoiGian = res.result.expires
					this._ThoiGian = res.result.expires;
					this.countdownInterval  = setInterval(() => {
						this._ThoiGian--;
						if (this._ThoiGian <= 0) {
						  this.stopCountdown();
						}
					  }, 1000); 
				} else if (res.success === "ALREADY_EXISTS") {
					this._ThoiGian = res.result.expires;
				} else{
					this.toastr.error("Lỗi không gửi được OTP")
					this._ThoiGian = 0;
				}
			},
			(error) => {
				console.error(error)
			}
		)
	}
	checkotp: number = 0
	_noidung : string = ""
	register(): void {
		let req = {
			SoDienThoai : this.user.phone,
			Otp : this._so1+this._so2+this._so3+this._so4+this._so5+this._so6
		  }
		this.authenService.CheckRegisterOtp(req).subscribe(
			(res) => {
				if (res.success === "OK") {
					if (this.isNotRobot == false) {
						this.isContentLoading = false
						return
					}
					if (this.isAccept == false) {
						this.isContentLoading = false
						return
					}
					this.authenService.registerSSO(this.user).subscribe(
						(data) => {
							if (data.success === "OK") {
			
								if (data.result != null) {
									this.user.id = data.result.id
									this.user.userCode = data.result.userCode
									this.user.roleIds = "1"
									this.user.permissionIds = ''
									this.authenService.insert(this.user).subscribe(
										(res) => {
											if (res.success === "OK") {
												this.user = new UserObject2();
												this.thanhcong = 1;
												this.toastr.success("Đăng ký tài khoản thành công")
											} else {
												this.toastr.error(data.message)
												this.user = new UserObject2();
												this.thanhcong = 0;
											}
										},
										(error) => {
											console.error(error)
										}
									)
								}
							} else {
								this.toastr.error(data.message)
								this.thanhcong = 0;
							}
						},
						(error) => {
							console.error(error)
						}
					)
				} else{
					this.checkotp = 1
					 this._noidung = res.message
				}
			},
			(error) => {
				this.toastr.error("Lỗi không gửi được OTP")
			}
		)


	}



	login(): void {
		this._router.navigate(['/dang-nhap'])
	}
	//get email() { return this.forgetPasswordForm.get('email'); }
	get f() {
		return this.forgetPasswordForm.controls
	}

	checkPassWord(event) {
		let val = event.target.value;

		if (this.password1 != this.user.password) {
			this.checkPass = false;
		}
		else {
			this.checkPass = true;
		}
	}

}
