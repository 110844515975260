export class AppSettings {
    // dev
	// public static API_ADDRESS = 'http://localhost/LHPay_ThanhVien/api/'
	// public static HostingAddress = 'http://localhost:4200'
    // public static API_DOWNLOADFILES = 'http://localhost/Api_QLDT_2'
	
	// build --prod
	public static API_ADDRESS = 'https://apithanhvien.lhhtxocop.vn/api/'



	public static API_DOWNLOADFILES = 'https://apithanhvien.lhhtxocop.vn'
	public static HostingAddress = 'https://thanhvien.lhhtxocop.vn'
	

	public static VIEW_FILE = 'http://localhost/DocViewer?fileurl='
	public static FireBaseKey = 'AIzaSyAxcCjXztaBDnB3EevWjznUt7fem-JA8yc'
	public static DataFireStore = 'car'
	public static NotificationFireStore = 'Notifications'
	//public static SIGNALR_ADDRESS = 'http://localhost/Api_QLDT_2/signalr'

	public static API_VIEW_FILES = 'https://viewfile.lhhtxocop.vn/SSO'
	public static SIGNALR_ADDRESS = 'https://apithanhvien.lhhtxocop.vn/signalr'

	public static API_ADDRESS_SSO = 'https://apisso.lhhtxocop.vn/api/'

	//public static API_ADDRESS_SSO = 'http://localhost/LHPaySSO_Api/api/'

	//
	//public static API_ADDRESS = 'http://14.177.236.88:1221/api/'
	//public static API_DOWNLOADFILES = 'http://14.177.236.88:1221'
	//public static VIEW_FILE = 'http://14.177.236.88:6100/DocViewer?fileurl='
	//public static FireBaseKey = 'AIzaSyAxcCjXztaBDnB3EevWjznUt7fem-JA8yc'
	//public static DataFireStore = 'car'
	//public static NotificationFireStore = 'Notifications'
	//public static SIGNALR_ADDRESS = 'http://14.177.236.88:1221/signalr'

	//public static API_ADDRESS = 'https://pcccapp.ziz.vn:8581/api/'
	//public static API_DOWNLOADFILES = 'https://pcccapp.ziz.vn:8581'
	//public static VIEW_FILE = 'https://pcccapp.ziz.vn:8582/DocViewer?fileurl='
	//public static FireBaseKey = 'AIzaSyAxcCjXztaBDnB3EevWjznUt7fem-JA8yc'
	//public static DataFireStore = 'car_demo'
	//public static NotificationFireStore = 'Notifications'
	//public static SIGNALR_ADDRESS = 'https://pcccapp.ziz.vn:8581/signalr'

	//public static API_ADDRESS = 'https://pcccapp.bacninh.gov.vn:8581/api/'
	//public static API_DOWNLOADFILES = 'https://pcccapp.bacninh.gov.vn:8581'
	//public static VIEW_FILE = 'https://pcccapp.bacninh.gov.vn:8582/DocViewer?fileurl='
	//public static FireBaseKey = 'AIzaSyAnVVNhsSkPWcIXNmGmWd-rSNp7JAxlSww'
	//public static NotificationFireStore = 'Notifications'
	//public static DataFireStore = 'car_demo'
	//public static SIGNALR_ADDRESS = 'https://pcccapp.bacninh.gov.vn:8581/signalr'
}
