import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ServiceInvokerService } from '../commons/service-invoker.service'
import { Observable } from 'rxjs'
import { AppSettings } from '../constants/app-setting'
import { Api } from '../constants/api'
// import { retry } from 'rxjs/operators';
// import { request } from 'http';
import { UserInfoStorageService } from '../commons/user-info-storage.service'
import { LOG_ACTION, LOG_OBJECT } from '../constants/CONSTANTS'
import { BrowserInforService } from '../commons/browser-infor.service'

@Injectable({
	providedIn: 'root',
})
export class UserService {
	constructor(private browser: BrowserInforService, private http: HttpClient, private serviceInvoker: ServiceInvokerService, private localStronageService: UserInfoStorageService) {}

	getAllPagedList(query: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.GETLIST),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER),
		}
		return this.serviceInvoker.getwithHeaders(query, AppSettings.API_ADDRESS_SSO + Api.UserGetPagedList,headers)
	}
	getAllPagedListLocal(query: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.GETLIST),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER),
		}
		return this.serviceInvoker.getwithHeaders(query, AppSettings.API_ADDRESS + Api.UserGetPagedList,headers)
	}
	getById(query: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.GETINFO),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER),
		}
		return this.serviceInvoker.getwithHeaders(query, AppSettings.API_ADDRESS + Api.UserGetById,headers)
	}

	getByIdView(query: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.GETINFO),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER),
		}
		return this.serviceInvoker.getwithHeaders(query, AppSettings.API_ADDRESS + Api.UserGetByIdView,headers)
	}
	

	getByIdUpdate(query: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.GETINFO),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER),
		}
		return this.serviceInvoker.getwithHeaders(query, AppSettings.API_ADDRESS_SSO + Api.UserGetByIdUpdate,headers)
	}
	getByIdUpdateLocal(query: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.GETINFO),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER),
		}
		return this.serviceInvoker.getwithHeaders(query, AppSettings.API_ADDRESS + Api.UserGetByIdUpdate,headers)
	}
	getDataForCreate(query: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.GETLIST),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER_GET_BY_ROLE),
		}
		return this.serviceInvoker.getwithHeaders(query, AppSettings.API_ADDRESS + Api.UsersGetDataForCreate, headers)
	}
	insert(data: any, files: any = null): Observable<any> {
		let form = new FormData()

		for (let item in data) {
			form.append(item, data[item])
		}
		if (files != null && files.length > 0) {
			for (let item of files) {
				form.append('files', item, item.name)
			}
		}
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.INSERT),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER),
		}
		return this.serviceInvoker.postwithHeaders(form, AppSettings.API_ADDRESS_SSO + Api.UserInsert, headers)
	}
	insertLocal(data: any, files: any = null): Observable<any> {
		let form = new FormData()

		for (let item in data) {
			form.append(item, data[item])
		}
		if (files != null && files.length > 0) {
			for (let item of files) {
				form.append('files', item, item.name)
			}
		}
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.INSERT),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER),
		}
		return this.serviceInvoker.postwithHeaders(form, AppSettings.API_ADDRESS + Api.UserInsert, headers)
	}
	update(data: any, files: any = null): Observable<any> {
		let form = new FormData()

		for (let key in data) {
			form.append(key, data[key])
		}
		if (files != null && files.length > 0) {
			for (let item of files) {
				form.append('files', item, item.name)
			}
		}
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.UPDATE),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER),
		}
		return this.serviceInvoker.postwithHeaders(form, AppSettings.API_ADDRESS_SSO + Api.UserUpdate, headers)
	}
	updateLocal(data: any, files: any = null): Observable<any> {
		let form = new FormData()

		for (let key in data) {
			form.append(key, data[key])
		}
		if (files != null && files.length > 0) {
			for (let item of files) {
				form.append('files', item, item.name)
			}
		}
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.UPDATE),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER),
		}
		return this.serviceInvoker.postwithHeaders(form, AppSettings.API_ADDRESS + Api.UserUpdate, headers)
	}

	updateStatus(data: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.UPDATESTATUS),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER),
		}
		return this.serviceInvoker.postwithHeaders(data, AppSettings.API_ADDRESS_SSO + Api.UserUpdateStatus, headers)
	}
	updateStatusLocal(data: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.UPDATESTATUS),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER),
		}
		return this.serviceInvoker.postwithHeaders(data, AppSettings.API_ADDRESS + Api.UserUpdateStatus, headers)
	}
	resetCountLock(data: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.UNLOCK),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER),
		}
		return this.serviceInvoker.postwithHeaders(data, AppSettings.API_ADDRESS_SSO + Api.UserResetCountLock, headers)
	}
	delete(data: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.DELETE),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER),
		}
		return this.serviceInvoker.postwithHeaders(data, AppSettings.API_ADDRESS_SSO + Api.UserDelete, headers)
	}

	getAvatar(id: number): Observable<any> {
		let url = AppSettings.API_ADDRESS_SSO + Api.UserGetAvatar + '/' + id
		return this.serviceInvoker.get({}, url)
	}
	changePassword(data: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.CHANGEPASSWORD),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER),
		}
		return this.serviceInvoker.postwithHeaders(data, AppSettings.API_ADDRESS_SSO + Api.UserChangePassword, headers)
	}

	getIsSystem(query: any): Observable<any> {
		return this.serviceInvoker.get(query, AppSettings.API_ADDRESS + Api.UserGetIsSystem)
	}

	getIsSystemNotInGroupUser(query: any): Observable<any> {
		return this.serviceInvoker.get(query, AppSettings.API_ADDRESS + Api.UserGetIsSystemNotInGroupUser)
	}

	

	getForCreateRole(query: any): Observable<any> {
		return this.serviceInvoker.get(query, AppSettings.API_ADDRESS + Api.UserGetForCreateRole)
	}
	getByRoleIdOnPage(query: any): Observable<any> {
		return this.serviceInvoker.get(query, AppSettings.API_ADDRESS + Api.UserGetByRoleIdOnPage)
	}
	getByGroupUserIdOnPage(query: any): Observable<any> {
		return this.serviceInvoker.get(query, AppSettings.API_ADDRESS + Api.UserGetByGroupUserIdOnPage)
	}

	deleteUserRole(data: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.DELETE),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER_GET_BY_ROLE),
		}
		return this.serviceInvoker.postwithHeaders(data, AppSettings.API_ADDRESS + Api.DeleteUserRole, headers)
	}

	insertMultiUserRole(query: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.INSERT),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER_GET_BY_ROLE),
		}
		return this.serviceInvoker.postwithHeaders(query, AppSettings.API_ADDRESS + Api.InsertMultiUserRole, headers)
	}

	getByRoleId(query: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.GETLIST),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER_GET_BY_ROLE),
		}
		return this.serviceInvoker.getwithHeaders(query, AppSettings.API_ADDRESS + Api.UserGetByRoleId, headers)
	}

	userSystemUpdate(data: any, files: any = null): Observable<any> {
		let form = new FormData()

		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.UPDATE),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER),
		}

		for (let key in data) {
			form.append(key, data[key])
		}
		if (files != null && files.length > 0) {
			for (let item of files) {
				form.append('files', item, item.name)
			}
		}
		return this.serviceInvoker.postwithHeaders(form, AppSettings.API_ADDRESS_SSO + Api.UserSystemUpdate, headers)
	}
	userSystemUpdateLocal(data: any, files: any = null): Observable<any> {
		let form = new FormData()

		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.UPDATE),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER),
		}

		for (let key in data) {
			form.append(key, data[key])
		}
		if (files != null && files.length > 0) {
			for (let item of files) {
				form.append('files', item, item.name)
			}
		}
		return this.serviceInvoker.postwithHeaders(form, AppSettings.API_ADDRESS + Api.UserSystemUpdate, headers)
	}
	userSystemUpdate_More(data: any, files: any = null, filesCccd1: any = null, filesCccd2: any = null): Observable<any> {
		let form = new FormData()

		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.UPDATE),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER),
		}

		for (let key in data) {
			form.append(key, data[key])
		}
		if (files != null && files.length > 0) {
			for (let item of files) {
				form.append('files', item, item.name)
			}
		}
		if (filesCccd1 != null && filesCccd1.length > 0) {
			for (let item of filesCccd1) {
				form.append('cccd1', item, item.name)
			}
		}
		if (filesCccd2 != null && filesCccd2.length > 0) {
			for (let item of filesCccd2) {
				form.append('cccd2', item, item.name)
			}
		}
		return this.serviceInvoker.postwithHeaders(form, AppSettings.API_ADDRESS_SSO + Api.UserSystemUpdateMore, headers)
	}
	insertMultiUserGroupUser(query: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.INSERT),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER_GET_BY_GROUP),
		}
		return this.serviceInvoker.postwithHeaders(query, AppSettings.API_ADDRESS + Api.InsertMultiUserGroupUser, headers)
	}

	getByGroupUserId(query: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.GETLIST),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER_GET_BY_GROUP),
		}
		return this.serviceInvoker.getwithHeaders(query, AppSettings.API_ADDRESS + Api.UserGetByGroupUserId, headers)
	}

	deleteUserGroupUser(data: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.DELETE),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER_GET_BY_GROUP),
		}
		return this.serviceInvoker.postwithHeaders(data, AppSettings.API_ADDRESS + Api.DeleteUserGroupUser, headers)
	}

	checkExists(req: any): Observable<any> {
		let url = AppSettings.API_ADDRESS + Api.UserCheckExists
		return this.serviceInvoker.get(req, url)
	}
	checkExistsCode(req: any): Observable<any> {
		let url = AppSettings.API_ADDRESS + Api.UserCheckExistsCode
		return this.serviceInvoker.get(req, url)
	}
	checkHasCode(req: any): Observable<any> {
		let url = AppSettings.API_ADDRESS + Api.UserCheckHasCode
		return this.serviceInvoker.get(req, url)
	}
	checkExistsCodeReturn(req: any): Observable<any> {
		let url = AppSettings.API_ADDRESS + Api.UserCheckExistsCodeReturn
		return this.serviceInvoker.get(req, url)
	}
	getLinkApp(req: any): Observable<any> {
		let url = AppSettings.API_ADDRESS + Api.UserGetLinkApp
		return this.serviceInvoker.get(req, url)
	}
	updateUserBank(data: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.UPDATESTATUS),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER),
		}
		return this.serviceInvoker.postwithHeaders(data, AppSettings.API_ADDRESS + Api.UserBankUpdate, headers)
	}
	getUserBankById(query: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.GETINFO),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER),
		}
		return this.serviceInvoker.getwithHeaders(query, AppSettings.API_ADDRESS + Api.UserBankGetByID,headers)
	}
	updateCode(data: any): Observable<any> {
		let headers = {
			logAction: encodeURIComponent(LOG_ACTION.UPDATESTATUS),
			logObject: encodeURIComponent(LOG_OBJECT.SY_USER),
		}
		return this.serviceInvoker.postwithHeaders(data, AppSettings.API_ADDRESS + Api.UserUpdateCode, headers)
	}
}
