import { Component, OnInit, ViewChild, ChangeDetectorRef, HostListener, ViewEncapsulation } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material'
import { Inject } from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { AppSettings } from '../../constants/app-setting'
import { HttpClient } from '@angular/common/http'
import { Api } from 'src/app/constants/api'
declare var $: any

@Component({
	selector: 'app-view-export-dialog',
	encapsulation: ViewEncapsulation.None,
	templateUrl: './view-export-dialog.component.html',
	styleUrls: ['./view-export-dialog.component.css',
		"../../../../node_modules/jquery-ui/themes/base/all.css",
		"../../../../node_modules/devextreme/dist/css/dx.common.css",
		"../../../../node_modules/devextreme/dist/css/dx.light.css",
		"../../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css",
		"../../../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css",
		"../../../../node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css",
	],
})
export class ViewExportDialogComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private toastr: ToastrService,
		private http: HttpClient,
		private cdRef: ChangeDetectorRef,
		public dialogRef: MatDialogRef<ViewExportDialogComponent>
	) { }

	// The URL of a report to open in the Report Designer when the application starts.
	reportUrl: string;

	// URI of your backend project.
	hostUrl: string = AppSettings.API_DOWNLOADFILES + "/";

	// Use this line if you use an ASP.NET MVC backend
	// invokeAction: string = '/WebDocumentViewer/Invoke';
	// Uncomment this line if you use an ASP.NET Core backend
	invokeAction: string = "DXXRDV/";

	screenHeight: number;
	scrollHeight: string;
	ngOnInit() {
		this.onResize();
		this.reportUrl = this.data.reportUrl;
	}

	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenHeight = window.innerHeight;
		this.scrollHeight = (this.screenHeight - 75) + "px";
	}

	closeDialog() {
		this.dialogRef.close('');
	}

	BeforeRender(pEvent: any) {
		pEvent.args.reportPreview.zoom(1);
	}

}
