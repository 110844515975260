export class UserObject {
	constructor() {
		this.ma = -1
		this.tenDangNhap = null
		this.hoTen = ''
		this.homThu = ''
		this.gioiTinh = true
		this.loginId = ''
		this.unitId = null
		this.departmentId = null
		this.kichHoat = true
		this.isHaveToken = false
		this.isLeader = false
		this.unitIdCapSo = 0
		this.accountType = 0
		this.maChucVu = null
		this.checkFileChange = false
		this.listPhongBan = []
		this.role = 2
		this.diaChi = ''
		this.dienThoai = ''
		this.avatar = ''
	}
	ma: number
	tenDangNhap: string
	loginId: string
	hoTen: string
	homThu: string
	dienThoai: string
	ngaySinh: string
	gioiTinh: boolean
	unitId: number
	departmentId: number
	kichHoat: boolean
	isHaveToken: boolean
	isLeader: boolean
	unitIdCapSo: number
	accountType: number
	donViId: number
	anhDaiDien: string
	phongBanId: number
	xoa: boolean
	maChucVu: boolean
	matKhau: string
	isSuperAdmin: boolean
	checkFileChange: boolean
	listPhongBan: any[]
	role: number
	diaChi: string
	avatar: string
}

export class UserSystemObject {
	constructor() {
		this.id = 0
		this.fullName = ''
		this.userName = ''
		this.email = ''
		this.phone = ''
		this.isActived = true
		this.isDeleted = false
		this.gender = null
		this.isAdmin = true
		this.address = ''
		this.ngaySinh = ''
		this.ngayCap = ''
		this.noiCap = ''
		this.soCccd = ''
		this.isVerified = false
		this.danToc = ''
		this.quocTich = ''
	}

	id: number
	fullName: string
	userName: string
	isActived: boolean
	isDeleted: boolean
	gender: boolean
	isAdmin: boolean
	email: string
	phone: string
	countLock: number
	lockEndOut: string
	avatar: string
	address: string
	ngaySinh : string
	soCccd : string
	ngayCap : string
	noiCap : string
	anhCccd1 : string
	anhCccd2 : string
	isVerified: boolean
	danToc : string
	quocTich : string
}

export class UserObject2 {
	constructor() {
		this.fullName = ''
		this.userName = ''
		this.email = ''
		this.phone = ''
		this.positionId = null
		this.roleIds = null
		this.isActived = true
		this.isDeleted = false
		this.gender = true
		this.type = 1
		this.isSuperAdmin = false
		this.typeId = 1
		this.isActived = true
		this.rank = ''
		this.ngaySinh = ''
		this.ngayCap = ''
		this.noiCap = ''
		this.soCccd = ''
		this.code = ''
		this.isLeader = false
		this.userCode = ''
		this.password = ''
	
	}

	id: number
	typeId: number
	fullName: string
	userName: string
	salt: string
	password: string
	isActived: boolean
	isDeleted: boolean
	gender: boolean
	type: number
	isSuperAdmin: boolean
	email: string
	phone: string
	unitId: number
	countLock: number
	lockEndOut: string
	avatar: string
	address: string
	positionId: number
	rank: string
	roleIds: string
	permissionIds: string
	ngaySinh : string
	soCccd : string
	ngayCap : string
	noiCap : string
	code : string
	isLeader : boolean
	userCode : string
	
}

export class UserChangePassword {
	id: number
	newPassword: string
	confirmPassword: string
	userName: string
	constructor() {
		this.id = 0
		this.newPassword = ''
		this.confirmPassword = ''
		this.userName = ''
	}
}

export class UserCheckExist {

	phone: string
	email: string
	userName: string

	constructor() {
		this.userName = ''
		this.email = ''
		this.phone = ''
	}
}
export class UserBankObject {
	id: number
	userId: number
	tenNganHang: string
	soTaiKhoan: string
	tenTaiKhoan: string
	chiNhanh: string


	constructor() {
		this.id = 0
		this.userId = 0
		this.tenNganHang = ''
		this.soTaiKhoan = ''
		this.tenTaiKhoan = ''
		this.chiNhanh = ''
	}
}