<div *ngIf="isContentLoading" class="loading">
    <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
    </div>
</div>
<div class="authincation h-100 ">
	<div class="container-fluid h-100">
		<div class="row justify-content-center h-100 bgroud-forgot-password align-items-center">
			<div class="col no-padding">
				<div class="authincation-content bg-transparent shadow-none">
					<div class="row no-gutters">
                        <div class="col-md-12 no-padding bg-mobile">
							
                            <img class="img-fluid bg-mobile-image" src="assets/dist/images/mobilelogo.png" alt="">
                    
                        </div>
						 <div class="col-sm-12 col-md d-flex justify-content-center align-items-center">
                            <!-- <div class="auth-form" style="max-width: 700px" >
                              
                            </div> -->
                            <div class="col-sm-12 col-md d-flex justify-content-center align-items-center">
							<div class="auth-form" style="max-width: 550px; padding-top:30px;" *ngIf="thanhcong == 0">
                                
								<form class="login100-form validate-form flex-sb flex-w" [formGroup]="forgetPasswordForm"
									#formDir="ngForm" (ngSubmit)="CheckCode()">
									<div class="form-row mb-4">
                                        <div class="col-12 col-lg-12 form-group" >
                                           <div class="title-register">Nhập thông tin người giới thiệu</div>
                                        </div>
                                        <div class="col-12 col-lg-12 form-group" >
                                            <div class="row">
                                                <div class="col-4 no-padding-right">
                                                    <label class="mb-1">Số điện thoại <span>*</span></label>
                                                </div>   
                                                <div class="col-8">
                                                    <input  svTrim class="form-control form-control-sm border-brown1" placeholder="Nhập..." (keyup.enter)="onCheckExist()" 
                                                    [(ngModel)]="_code" formControlName="code" minlength="10" maxlength="14"> 
                                                <div *ngIf="(submitted && f.code.invalid)||(f.code.invalid && (f.code.dirty || f.code.touched))"
                                                    class="has-error m-b-12">
                                                    <div *ngIf="f.code.errors.required">
                                                        <span class="help-block">Trường dữ liệu này không được để trống</span>
                                                    </div>
                                                    <div *ngIf="f.code.errors.minlength">
                                                        <span class="help-block">Số điện thoại không đúng định dạng</span>
                                                    </div>
                                                    <div *ngIf="f.code.errors.maxlength">
                                                        <span class="help-block">Số điện thoại không đúng định dạng</span>
                                                    </div>
                                                </div>
                                                </div> 
                                            </div>
                                        </div>
                                        <!-- <div class="col-12 col-lg-6 form-group" >
                                            <div class="row">
                                                <div class="col-5 no-padding-right">
                                                    <label class="mb-1">Người giới thiệu </label>
                                                </div>
                                                <div class="col-7">
                                                    <input [readonly]="true"  svTrim class="form-control form-control-sm border-brown1" placeholder=""
                                                    [(ngModel)]="_nhaTaiTro" [ngModelOptions]="{standalone: true}"> 
                                                </div>
                                            </div>              
                                        </div> -->
                                        
                                     
									</div>
                                   
                                    <div class="form-row">

                                        <div class="col-12 col-lg-12 form-group" >
                                            <div class="text-center">
                                                <button type="button" class="btn btn-forgot  btn-block" (click)="CheckCode()">Xác nhận</button>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-12 form-group" >
                                            <div class="text-center">
                                                <button type="button" class="btn  bg-white text-dark border"  style="font-size: 16px;"
                                                    (click)="login()">
                                                    <i class="bi bi-arrow-left"></i> Về trang đăng nhập</button>
                                            </div>
                                        </div>
                                       
                                    </div>
                                    <!-- <div class="text-center">
                                        <div class="form-group">
                                            <span class="label-login1">Đã có tài khoản ?</span> <a href="/dang-nhap" style="cursor: progress;">Đăng nhập</a>
                                        </div>
                                        <div class="form-group">
                                            <span  class="label-login1">Quên mật khẩu ?</span><a href="/quen-mat-khau"  style="cursor: progress;">Đổi mật khẩu</a>
                                        </div>
                                    </div> -->
									
								</form>
							</div>
                        </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

<!-- <div class="modal fade" id="modal-otp">
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header d-block">
				<h3 class="modal-title text-center text-uppercase">xác thực mã otp</h3>
				<p class="text-center">Mã xác thực đã được gửi về email của anh/chị:</p>
				
				<button type="button" class="close" data-dismiss="modal"><span>&times;</span>
				</button>
			</div>
			<div class="modal-body" style="background: #FEF5EC">
				<div class="otp-code">
					<p class="text-center">Nhập mã OTP</p>
					<div class="input-group-otp d-flex justify-content-center">
						<input type="text" numbersOnly maxlength="1" class="form-control border-brown text-center mr-2">
						<input type="text" numbersOnly maxlength="1" class="form-control border-brown text-center mr-2">
						<input type="text" numbersOnly maxlength="1" class="form-control border-brown text-center mr-2">
						<input type="text" numbersOnly maxlength="1" class="form-control border-brown text-center mr-2">
						<input type="text" numbersOnly maxlength="1" class="form-control border-brown text-center mr-2">
						<input type="text" numbersOnly maxlength="1" class="form-control border-brown text-center">
					</div>
				</div>
			</div>
			<div class="modal-footer justify-content-center">
				<button type="button" class="btn btn-secondary grd-xanhngoc rounded-xl border-0 text-uppercase">Xác thực</button>
			</div>
		</div>
	</div>
</div> -->