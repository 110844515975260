import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthenticationService } from '../../../../services/authentication.service'
import { ForgetPasswordUserObject } from '../../../../models/forgetPasswordUserObject'
import { ToastrService } from 'ngx-toastr'
import { UserCheckExist, UserObject2 } from 'src/app/models/UserObject'
import { ConfirmedPassValidator } from 'src/app/directives/pass.validator';
import { UserService } from 'src/app/services/user.service'
import { environment } from 'src/environments/environment'
import { UserInfoStorageService } from 'src/app/commons/user-info-storage.service'

@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.css']
})
export class CheckComponent implements OnInit {

  env = environment
	submitted: boolean = false
	user: UserObject2 = new UserObject2()
	forgetPasswordForm: FormGroup
	constructor(
		private authenService: AuthenticationService,
		private _avRoute: ActivatedRoute,
		private _router: Router,
		private toastr: ToastrService,
		private formBuilder: FormBuilder,
		private activeRoute: ActivatedRoute,
		private userService: UserService,
    private storageService: UserInfoStorageService,
	) { }
	listGender: any = [
		{ value: true, text: 'Nam' },
		{ value: false, text: 'Nữ' },
	]
   model : any = {}
	_ngaySinh: Date = null;
	_ngayCap: Date = null;
	password1: string = ""
	checkPass: boolean = false
	thanhcong: number = 0

	_code: string = ""
	_nhaTaiTro: string = ""
	_phone: string = ""
	_userName: string = ""
	_password: string = ""
	_password1: string = ""
	_email: string = ""

	step: number = 1
	inputs: ElementRef[];
	userExit: UserCheckExist = new UserCheckExist()
	@ViewChild('input0', { static: false }) input0: ElementRef;
	@ViewChild('input1', { static: false }) input1: ElementRef;
	@ViewChild('input2', { static: false }) input2: ElementRef;
	@ViewChild('input3', { static: false }) input3: ElementRef;
	@ViewChild('input4', { static: false }) input4: ElementRef;
	@ViewChild('input5', { static: false }) input5: ElementRef;
  
	onInputChange(index: number , event : Event) {
		debugger
		const input = this.inputs[index].nativeElement;
		var val = (event as KeyboardEvent).key;
		if (!isNaN(parseInt(val))) {
			input.value = val; // Thêm giá trị vào ô input nếu là số
		} 
		//input.value = (event as KeyboardEvent).key;
		if (input.value && index < this.inputs.length - 1) {
			this.inputs[index + 1].nativeElement.focus();
		}
	}

	ngAfterViewInit() {
		// this.inputs = [
		// 	this.input0,
		// 	this.input1,
		// 	this.input2,
		// 	this.input3,
		// 	this.input4,
		// 	this.input5,
		// ];
	}
	ngOnInit() {
		this.forgetPasswordForm = this.formBuilder.group({
			code: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(14)]],
		})

		// this.activeRoute.params.subscribe((params) => {
		// 	if (params['code']) {
		// 		if (params['code'] != '' && params['code'] != '0')
		// 			this._code = params['code']

		// 		let request = { UserCode: this._code }
		// 		this.userService.checkExistsCodeReturn(request).subscribe(
		// 			(resExist) => {
		// 				if (resExist.success === "OK") {
		// 					if (resExist.result.SYUserCheckExists.exists == true) {
		// 						this.user.code = resExist.result.SYUserCheckExists.value
		// 						this._code = resExist.result.SYUserCheckExists.phone
		// 						this._nhaTaiTro = resExist.result.SYUserCheckExists.name
		// 					}
		// 				}
		// 			},
		// 			(error) => {

		// 				console.error(error)
		// 			}
		// 		)
		// 	}
		// })

	}

	_type: string = "password"
	HienThi: number = 0
	_type1: string = "password"
	HienThi1: number = 0
	isContentLoading: boolean = false
	isNotRobot: boolean = false
	isAccept: boolean = false



	CheckCode() {

		this.isContentLoading = true

		this.submitted = true
		if (this.forgetPasswordForm.invalid) {
			this.isContentLoading = false
			return
		}

    this.model.Id = this.storageService.getUserId()
    this.model.Code = this._code

    
		this.userService.updateCode(this.model).subscribe(
			(data) => {

				if (data.success === "OK") {
					this.storageService.setCode(this._code)
          this._router.navigate(['/quan-tri/nghiep-vu/ban-lam-viec-doi-tac'])

				} else {

					this.isContentLoading = false
					this.toastr.error(data.message)
				}

			},
			(error) => {
				this.isContentLoading = false
				console.error(error)
			}
		)
	}

	onCheckExist() {

	}

	login(): void {
    this.storageService.clearStoreageAll();
		this._router.navigate(['/dang-nhap'])
	}


	get f() {
		return this.forgetPasswordForm.controls
	}



}

